<template>
    <div class="appoimentDetailParentClass">
        <v-card class="AppointmentDetailContainer" flat>
            <div class="detailsStyle">
                <div class="commanDisplayContainer">
                    <img class="imageStyle" src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/commonNurseIcon.svg" />
                    <div style="display: flex; flex-direction: column; align-items: flex-start;">
                        <h5>{{ nurseType.nurseType }}</h5>

                        <span>{{ $i18n.locale == 'en' ? SpecialtyDetails.skillName : SpecialtyDetails.skillNameMM}}</span>
                    </div>
                    
                </div>
                <div class="dateShiftStyle">
                    <div class="commanDisplayContainer">
                        <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/calendar.svg" />
                        <span>{{ `${formatDate(bookingStartDate)} - ${formatDate(bookingEndDate)}` }}</span>
                    </div>
                    <div class="commanDisplayContainer">
                        <img style="width: 24px; height: 24px;" :src="(shiftType === 'Morning Shift') ?  'https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/morningShiftIcon.svg' : 'https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/nightShiftIcon.svg' "/>
                        <span>{{ shiftType }}</span>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'appoimentDetail',
    components: {},
    data() {
        return {
            nurseType: '',
            bookingStartDate: '',
            bookingEndDate: '',
            shiftType: '',
            monthNames: ["JAN", "FEB", "MAR", "APR", "May", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"],
            SpecialtyDetails: ''
        }
    },
    methods: {
        formatDate(date) {
            const dateObj = new Date(date);
            const day = dateObj.getUTCDate();
            const month = this.monthNames[dateObj.getUTCMonth()];
            return `${day} ${month}`;
        }
    },
    mounted() {
        this.nurseType = this.$store.state.nurseBooking.nurseType
        let selectedDates = this.$store.state.nurseBooking.selectedDates
        this.bookingStartDate = selectedDates[0]
        this.bookingEndDate = selectedDates[selectedDates.length - 1]
        this.shiftType = this.$store.state.nurseBooking.shiftType
        this.SpecialtyDetails = this.$store.state.nurseBooking.nurseSpecialtyDetails
    }
}
</script>
<style>
.AppointmentDetailContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background-color: #F0F8FE !important;
}

.dateShiftStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.imageStyle {
    width: 36px;
    height: 36px;
}

.commanDisplayContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.commanDisplayContainer span {
    color: #333 !important;
    font-size: 13px;
}

.detailsStyle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.appoimentDetailParentClass {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}
</style>