<template>
    <div>
        <div justify="center">
            <v-dialog v-model="cannotProceedFlag" persistent max-width="290">
                <v-card>
                    <v-card-title style="word-break:normal">
                        {{ $t("Warning_Message.Cant_Proceed") }}
                    </v-card-title>
                    <v-card-actions>
                        <v-btn color="primary" depressed @click="closeCantProceedPopup" style="margin: auto;">
                            {{ $t("Warning_Message.Home_Page") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <v-app class="desktopLayoutClass">
            <div v-if="noNursesFoundFlag" class="noNurseLayout">
                <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/nurse_NA_alert.svg" />
                <div class="noNurseTextStyle">
                    <h3 style="font-size: 30px;">No Nurses Found!</h3>
                    <p>No Nurses available for the selected slots.
                        Sorry for the inconvenience caused.</p>
                </div>
                <div class="NoNurseButtonContainer">
                    <v-btn color="#48ACEF" class="primary" width="100%" @click="backToBooking()">Back</v-btn>
                    <v-btn color="#48ACEF" outlined width="100%" @click="gotoHome()">Go To Home</v-btn>
                </div>
            </div>
            <div v-else-if="searchNurseFlag">
                <div class="loader-wrapper">
                    <div class="loader"></div>
                    <p style="margin-left: 2rem;">Searching for nurse availability</p>
                </div>
            </div>
            <div v-else>
                <header class="headingContainer">
                    <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/back_button.svg" @click="takeMeBack"
                        style="cursor: pointer;" />
                    <h3>Appointment Booking</h3>
                </header>
                <div class="summaryPageParentClass">
                    <h5>Appointment Details</h5>
                    <appointmentDetailsComponent></appointmentDetailsComponent>
                    <v-spacer></v-spacer>
                    <h5>Patient Info</h5>
                    <patientInfo></patientInfo>
                    <v-spacer></v-spacer>
                    <h5>Patient Contact</h5>
                    <contactDetails :townShipName='townShipName' ></contactDetails>
                    <v-spacer></v-spacer>
                    <h5>Billing Details</h5>
                    <v-card flat class="pricingParent">
                        <div class="pricingDetailsStyle">
                            <span style="color: #7C8287; font-size: 13px; font-weight: 400; margin-bottom: 10px;">Amount</span>
                            <h5 style="font-size: 14px;">{{ amount }}</h5>
                        </div>
                        <div class="pricingDetailsStyle">
                            <span style="color: #7C8287; font-size: 13px; font-weight: 400; margin-bottom: 10px;">No.of days</span>
                            <h5 style="font-size: 14px;">{{ noOfDays }}</h5>
                        </div>
                        <div class="pricingDetailsStyle">
                            <div style="display: flex; flex-direction: column; align-items: flex-start;">
                                <span style="color: #27AE60; font-size: 18px; font-weight: 600;">Total</span>
                                <span style="color:#7C8287; font-size: 11px; font-weight: 400;">*Inclusive of all
                                    taxes</span>
                            </div>
                            <h5 style="color: #27AE60; margin-bottom: 10px; font-size: 18px;">{{ totalAmount }}Ks</h5>
                        </div>
                        <div style="background-color: #ffe900; text-align: center; font-size: 14px; border-radius: 4px;">
                            <div style="padding: 16px;">
                                <span>Double price will be charged in case of highly contagious diseases such as HIV infection, Hepatitis B or C, or TB or highly infectious cases.</span>
                            </div>
                        </div>
                    </v-card>
                </div>
                <v-footer class="footerContainer">
                    <div>
                        <h5>Total {{ totalAmount }} Ks</h5>
                    </div>
                    <v-btn class="primary buttonStyle" color="#48ACEF" @click="BookNowHandler()" :loading="isLoading" :disabled="isLoading">Pay Now to book</v-btn>
                </v-footer>
            </div>
        </v-app>
    </div>
</template>

<script>
import axios from 'axios';
import appointmentDetailsComponent from '../../components/nurseBookingComponents/appointmentDetails.vue';
import patientInfo from '../../components/nurseBookingComponents/patientInfo.vue';
import contactDetails from '../../components/nurseBookingComponents/contactDetails.vue';
import { axios_auth_instance_nurseBooking } from '../../utils/axios_utils';
import { mixinFunctions } from '../../mixins/index'
export default {
    name: 'summaryPage',
    mixins:[mixinFunctions],
    components: {
        appointmentDetailsComponent: appointmentDetailsComponent,
        patientInfo,
        contactDetails
    },
    data() {
        return {
            successScreenFlag: false,
            errorFlag: false,
            noNursesFoundFlag: false,
            isLoading: false,
            totalAmount: 0,
            amount: 0,
            noOfDays: 0,
            searchNurseFlag: true,
            townShipName:'',
            cannotProceedFlag: false
        }
    },
    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName: 'nurseBookingSummaryPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'nurseBookingSummaryPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL + "/userPageViewTable", userPageViewTableBody)
                .catch((userPageViewTableError) => {
                    console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
                });
        },
        takeMeBack() {
            this.$router.go(-1);
        },
        async BookNowHandler() {
            this.isLoading = true;
            let nurseType = this.$store.state.nurseBooking.nurseType.nurseType;
            let shiftTypeUnSplitted = this.$store.state.nurseBooking.shiftType;
            let shiftTypeSplitted = shiftTypeUnSplitted.split(' ');
            let dates = this.$store.state.nurseBooking.selectedDates;
            let patientDetails = this.$store.state.nurseBooking.patientDetails;
            let addressDetails = this.$store.state.nurseBooking.addressDetails;
            let description = this.$store.state.nurseBooking.description;
            let specialtyDetails = this.$store.state.nurseBooking.nurseSpecialtyDetails;
            let isMobile = screen.width < 520 ? true : false;

            let data = {
                'nurseType': nurseType,
                'shift': shiftTypeSplitted[0],
                'dates': JSON.stringify(dates),
                'patientDetails': {
                    ID2: patientDetails.sk, 
                },
                'addressDetails': addressDetails,
                'description': description,
                'specialtyDetails': specialtyDetails,
                'isMobile': isMobile,
            }
            if (screen.width >= 520) {
                data.tradeType = "PAY_BY_QRCODE";
            } else {
                data.tradeType = "PWAAPP";
            }
            try {
                await axios_auth_instance_nurseBooking.post("/appointments/createAppointment", data).then((startPaymentURLResponse) => {
                    if (startPaymentURLResponse && startPaymentURLResponse.data && startPaymentURLResponse.data.data && startPaymentURLResponse.data.data.message === "SUCCESS") {
                        console.log(`startPaymentURLResponse: ${startPaymentURLResponse.data.data}`);
                        let prepayID = startPaymentURLResponse.data.data.prePayData.prepayID;
                        let orderInfo = startPaymentURLResponse.data.data.prePayData.orderInfo;
                        let sign = startPaymentURLResponse.data.data.prePayData.sign;
                        let signType = startPaymentURLResponse.data.data.prePayData.signType;
                        let tradeType = startPaymentURLResponse.data.data.prePayData.tradeType;

                        if (startPaymentURLResponse.data.data == "NO_PAYMENT") {
                            if (startPaymentURLResponse.data.data.payment_status == "PAID") {
                                console.log('Proper Route has to be given');
                                // Proper Route Path has to be given
                                // this.$router.push({
                                // path:
                                //     "/customer/bookedSuccess?merch_order_id=" +
                                //     bookAppointmentResponse.data.data._id,
                                // });
                            }
                        } else if (!startPaymentURLResponse.data.data.qr_code && tradeType == "PWAAPP") {
                            var pwa_signed_url = startPaymentURLResponse.data.data.prePayData.PWA_signed_url;
                            window.location.replace(pwa_signed_url);
                        } else if (!startPaymentURLResponse.data.data.qr_code && tradeType == "MINIAPP" ) {
                            if (window.xm) {
                                window.xm.native('startPay', {
                                    prepayId: prepayID,
                                    orderInfo: orderInfo,
                                    sign: sign,
                                    signType: signType,
                                    tradeType: 'MINIAPP'
                                })
                                .then((startPaySuccess) => {
                                    if(startPaySuccess.resultCode == 2 || startPaySuccess.resultCode == 3) {
                                        //close deduction
                                    }                                    
                                })
                                .catch((startPayError) => {
                                    console.log("startPayError", startPayError)
                                    this.isLoading = false;
                                });
                                var queryOrderBody = {
                                    bookingID: startPaymentURLResponse.data.data.bookingID,
                                };
                                this.query_order(queryOrderBody);
                            } else {
                                console.log('No window.xm')
                            }
                        }
                    } else {
                        this.$router.push({
                        name: "QRCode",
                        params: {
                            booking_id: startPaymentURLResponse.data.data.prePayData.merchantOrderID,
                            qr_code:startPaymentURLResponse.data.data.prePayData.qr_code,
                            amount:startPaymentURLResponse.data.data.prePayData.amount
                        },
                    });
                    }
                }).catch((error) => {
                    if(error && error.response && error.response.data && error.response.data.data) {
                        this.errorMessageDialog = true;
                    } else {
                        this.errorMessageDialog = true;
                        console.log("error", error)
                    }
                    this.isLoading = false;
                });
            } catch (error) {
                console.error("Error fetching data ERROR:", error);
                if (error.response.status === 404) {
                    this.isLoading = false;
                    this.noNursesFoundFlag = true;
                }
                else if (error.response.status === 401) {
                    this.$cookies.remove('customerToken');
                    this.$router.push({
                        name: "Login",
                    });
                }
            }
        },
        async query_order(queryOrderBody) {
            console.log('In query_order function call');
			var query_order_function = setInterval(async () => {
				await axios_auth_instance_nurseBooking.post(
					"/appointments/kbzPayQueryOrderCall",
					queryOrderBody
				).then((queryResponse) => {
					let trade_status = queryResponse.data.data.trade_status;
                    let bookingID = queryOrderBody.bookingID;
					if (trade_status == "PAY_SUCCESS") {
						clearInterval(query_order_function);
                        this.successScreenFlag = true;
                        this.$router.push({ path: `/nurse/AppointmentSuccessPage/${bookingID}` });
						this.disableButton = false; 
                        this.isLoading = false;
					} else if (trade_status == "WAIT_PAY" || trade_status == "PAYING") {
						this.timeout_count += 1;
						this.loading_indication = "Waiting for payment: " + this.timeout_count.toString();
						if (this.timeout_count >= 20) {
							clearInterval(query_order_function);
						}
					} else {
						clearInterval(query_order_function);
					}
				})
				.catch((error) => {
					if(error && error.response && error.response.data && error.response.data.data) {
						this.errorMessageDialog = true;
					} else {
						this.errorMessageDialog = true;
						console.log("error", error)
					}
					clearInterval(query_order_function);
					this.isLoading = false;
				});
			}, 5000);
		},
        gotoHome() {
            if (!window.xm) {
				this.$router.push({
					name: 'CustomerHomeScreen'
				});
			} else {
                this.$router.push({ path: '/' });
            }
        },
        backToBooking() {
            this.$store.dispatch('changeShiftType', 'Morning Shift');
            this.$store.dispatch('changeSelectedDates', []);
            this.$router.go(-1);
        },
        async searchNurse() {
            this.searchNurseFlag = true
            let nurseType = this.$store.state.nurseBooking.nurseType.nurseType;
            let shiftTypeUnSplitted = this.$store.state.nurseBooking.shiftType;
            let shiftTypeSplitted = shiftTypeUnSplitted.split(' ');
            let dates = this.$store.state.nurseBooking.selectedDates;
            let specialtyDetails = this.$store.state.nurseBooking.nurseSpecialtyDetails;
            try {
                let result = await axios_auth_instance_nurseBooking.get("/nurseController/searchNurse", {
                    headers: {
                        'township': this.$store.state.nurseBooking.addressDetails.state_id,
                        'nurseType': nurseType,
                        'shift': shiftTypeSplitted[0],
                        'dates': JSON.stringify(dates),
                        'skillID': specialtyDetails.skillId
                    }
                });
                this.totalAmount = result.data.billingDetails.totalAmount;
                this.amount = result.data.billingDetails.amount;
                this.noOfDays = result.data.billingDetails.noOfDays;
                this.townShipName = result.data.townshipName;
                this.searchNurseFlag = false
            } catch (error) {
                console.error("bookAppointment ERROR:", error);
                this.$store.dispatch('changeShiftType', 'Morning Shift');
                this.$store.dispatch('changeSelectedDates', []);
                if (error.response.status === 404) {
                    this.searchNurseFlag = false
                    this.noNursesFoundFlag = true
                }
                else if (error.response.status === 401) {
                    this.$cookies.remove('customerToken');
                    this.$router.push({
                        name: "Login",
                    });
                }
            }
        },
        goToMyAppointments() {
            this.$router.push({ path: '/appointments' })
        },
        closeCantProceedPopup() {
            this.cannotProceedFlag = false;
            this.gotoHome()
        }
    },
    async mounted() {
        let nurseBookingHasValues = await this.$store.getters.nurseBookingHasValues;
        if(!nurseBookingHasValues){
            // Popup & redirect to Home Screen.
            this.cannotProceedFlag = true
            return
        }
        if (window.xm) {
            this.token = localStorage.getItem('customerToken');
        } else {
            this.token = this.$cookies.get('customerToken');
        }
        await this.searchNurse()
        this.userPageViewTable('NBASummaryPage');
    }
}
</script>

<style scoped>
.summaryPageParentClass {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 12px;
}

.headingContainer {
    display: flex;
    padding: 12px 20px;
    align-items: center;
    gap: 10px;
    border-bottom: 2px solid #E0E0E0 !important;
    background-color: #fff !important;
}

.footerContainer {
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px #E0E0E0 !important;
    background-color: #fff !important;
}

.buttonStyle {
    text-transform: capitalize;
}

.v-footer div h5 {
    color: #27AE60;
}

.v-footer div p {
    color: #1467BF;
    font-size: 12px;
    font-weight: 400;
}

.successScreenStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 60% 24px 10% 24px;
    width: 100%;
}

.noNurseLayout {
    display: inline-flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 20% 5% 0px 5%;
}

.loader-wrapper {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 8px solid #f3f3f3 !important;
    border-top: 8px solid #3498db !important;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

.NoNurseButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.noNurseTextStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.pricingParent {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid var(--grays-gray-5, #E0E0E0);
}

.pricingDetailsStyle {
    display: flex;
    padding-bottom: 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--grays-gray-6, #F2F2F2);
}
</style>